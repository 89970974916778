import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ProjectComponent from '../common/projectComponent';
import picture from '../../assets/projects/Aleart.png';

import About from './about';

const Aleart = () => {

  return (
    <ProjectComponent> 
      <AleartDiv>
        <img src={picture} alt="Aleart" />
      </AleartDiv>
      <About> 
        <p>Alexandra Artigas is a talented artist.</p>
        <a href="https://aleart.com"> Chech It Out > </a>
      </About> 
    </ProjectComponent> 
  )
}

export default Aleart;

const AleartDiv = styled(animated.div)
`
width: 100%;

img {
  width: 100%;

}

`

