import React from 'react';
import styled from 'styled-components';
import Socials from './socials';
import AboutMe from './aboutMe';
import Projects from './projects';

const HomeRight = (props) => {
  return (
    <div className="content grid right">
      <RightDiv>
        <TopPart >
          <h1> Anthony Fernando </h1>
          <h3> Full - Stack Developer | Computer Enthusiast </h3>
          <Socials />
        </TopPart>
        <AboutMe />
        <Projects />
      </RightDiv>
    </div>
  )
}

export default HomeRight;

const RightDiv = styled.div `
  display: grid;
  width: 90%;
  grid-auto-rows: min-content;
  grid-gap: 1em;
  justify-self: center;
  padding-top: 4em;

  *{ justify-self: center; }

  h1 { font-size: 2.5em;  color: #2c69f5;}
  h2 { font-size: 2em; }
  h3 { font-size: 1em; }
  p{ font-size: 1.6em; }
  a { color: #fff; }

  @media (min-width: 768px) {
    align-self: center;

    h1{ font-size: 3em; }
    h2 { font-size: 2.3em; }
    h3 { font-size: 1.3em; }
    p{ font-size: 1.9em; }
    a { color: #fff; }
  }
`

const TopPart = styled.div `
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 1em;

`
