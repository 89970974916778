import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GlobalStyles from './styles/global.js';
import HomeLeft from './components/home/homeLeft';
import HomeRight from './components/home/homeRight';

function App() {

  return (
    <div className="App wrapper">
      <GlobalStyles />
      <Switch>
        <Route path="/" component={HomeLeft} />
      </Switch>
      <Switch>
        <Route path="/" exact component={HomeRight} />
      </Switch>
    </div>
  );
}

export default App;
