import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Sprynet from '../projects/sprynet';
import SmashGG from '../projects/smash';
import Aleart from '../projects/aleart';

const Projects = (props) => {
  return (
    <div className="grid max-width">
      <h2>Projects</h2>
      <ProjectsDiv>
        <Sprynet />
        <SmashGG />
        <Aleart />
      </ProjectsDiv>
    </div>
  )
}

export default Projects;

const ProjectsDiv = styled.div `
  display: grid;
  grid-auto-rows: 19em;
  grid-gap: 1em;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;

`
