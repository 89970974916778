import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

export const About = ({children}) => {
  return (
    <StyledAbout>{children}</StyledAbout>
  );
}

const StyledAbout = styled(animated.div)
`
  display:grid;
  grid-template-rows: 1fr min-content;

  p {
    color: #fff;
  }

  a {
    background-color: #2c69f5;
    padding: .5em 3px;
    border-radius: .5em;
  }
`

export default About;
