import React from 'react'
import styled from 'styled-components';

const AboutMe = (props) => {
  return (
    <AboutMeDiv>
      <h2>Welcome!</h2>
      <p>{AboutMeTxt}</p>
      <p>{AboutMeTxt2}</p>
    </AboutMeDiv>
  )
}

export default AboutMe;

const AboutMeTxt = 'My name is Anthony Fernando. I am a Web Devlopler based in Raliegh-Durhman Area.'
const AboutMeTxt2 = 'I specialize in creating full stack web applications utilizing Figma, React, Express, and MongoDB.'


//const AboutMeTxt = 'Hi, my name is Anthony Fernando. I graduated from the University of California, Irvine in 2020 with a Bachelor of Science degree in Informatics, specializing in human computer interaction.'
// const AboutMeTxt2 = 'I built my first computer as a freshman in high school. Ever since then I have become obsesed with computers and their parts.'
// const AboutMeTxt2 = 'As an aspiring web developer, I enjoy pushing myself to familiarize myself with trending frameworks, designs, and practices.'

const AboutMeDiv = styled.div `
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 2em;
`