import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ProjectComponent from '../common/projectComponent';
import { ReactComponent as SprynetLogo } from '../../assets/projects/spry-net.svg';

import About from './about';

const Sprynet = () => {


  return (
    <ProjectComponent>
      <SprynetDiv>
        <SprynetLogo />
      </SprynetDiv>
      <About>
        <p>High-end, IT services company serving small to medium businesses in San Diego.</p>
        <a href="http://spry-net.com"> Check It Out > </a>
      </About>
    </ProjectComponent>
  )
}

export default Sprynet;

const SprynetDiv = styled(animated.div)
`
  svg{
    width: 100%;
    fill: #e2e2e2;
  }
`

