import React from 'react';
import styled from 'styled-components';
import picture from '../../assets/me.JPG';

const HomeLeft = (props) => {
  return (
    <div className="black-bg left">
      <ResponsiveImg href={picture} />
    </div>
  )
}

export default HomeLeft;

const ResponsiveImg = styled.div `
  background: url(${picture}) center no-repeat;
  background-size: cover;
  width: 90%;
  justify-self: center;

  @media(min-width: 769px){
    background-position: 0px 50px;
  }
`