import React from 'react'
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ProjectComponent from '../common/projectComponent';
import { ReactComponent as SmashGGLogo } from '../../assets/projects/smashgg-white.svg';

import About from './about';

const SmashGG = () => {
  return (
    <ProjectComponent>
      <SmashGGDiv>
        <SmashGGLogo />
      </SmashGGDiv>
      <About>
        <p>UX and UI research and redesign project</p>
        <a href="/download/SmashGG.pdf">Download Report</a>
      </About>
    </ProjectComponent>
  )
}

export default SmashGG;

const SmashGGDiv = styled(animated.div)
`
  svg{
    width: 100%;
  }
`

