import {
  createGlobalStyle
} from 'styled-components';

const GlobalStyles = createGlobalStyle `

  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 75%;
  }

  body {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;

    // overflow:hidden;
  }

  a {
    text-decoration: none;
  }

  li {
    list-style-type: none;
  }

  .wrapper {
    display:grid;
    grid-template: 'left' 75vh 'right' min-content / auto;

    @media(min-width:769px){
      grid-template: 'left right' 100vh / 2fr 1fr;

    }
    // width: 100vw;
  }

  .left {
    grid-area: left;
  }

  .right {
    grid-area: right;
  }

  .black-bg {
    display: grid;
    background-color: #000;
  }

  .content{
    overflow-y: scroll;
  }

  .grid {
    display: grid;
  }

  .max-width {
    width: 100%;
  }
`;

export default GlobalStyles;
