import React from 'react'
import styled from 'styled-components';
import { ReactComponent as Github } from '../../assets/socials/github.svg';
import { ReactComponent as LinkedIn } from '../../assets/socials/linkedin.svg';

const Socials = (props) => {
  return (
    <SocialsDiv>
      <a href="https://github.com/anthonee619" >
        <Github />
      </a>
      <a href="https://www.linkedin.com/in/roland-fernando-32210b172/" >
        <LinkedIn />
      </a>
    </SocialsDiv>
  )
}

export default Socials;

const SocialsDiv = styled.div `
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 2em;

  a {font-size: 1.6em }
  svg { width: 2em; }

  @media (min-width: 768px) {
    a {font-size: 1.9em;}
    
  }

`