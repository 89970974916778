import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

const ProjectComponent = ({ children }) => {

  const [isHovered, setIsHovered] = useState(false);

  const style = useSpring({ to: { opacity: 1 }, from: { opacity: -15 } })

  const handleMouseOver = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  if (isHovered) {
    return (
      <AnimatedProjectComponent
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        style={style}
        >
        {React.cloneElement(children[1], { style })}
      </AnimatedProjectComponent>

    )
  }
  return (
    <AnimatedProjectComponent
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      style={style}
      >
      {React.cloneElement(children[0], { style })}
    </AnimatedProjectComponent>
  )
}

export default ProjectComponent;

const AnimatedProjectComponent = styled(animated.div)
`
  display: grid;
  width: 75%;
  background-color: #2d3436;
  padding: 1em;
  border-radius: 1em;

  justify-content: center;
  align-content: center;
`
